import * as React from "react";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { SEO } from "../../../SEO/SEO";
import { useTranslation } from "react-i18next";
import coderOnePreview from "../../../Images/coder-one.jpg";
import { PricingContent } from "./PricingContent";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const SubscriptionPricing: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("coderOneIsAISandbox");
    const title = t("buildGameChangingAI");
    const socialImage = `https://${process.env.GATSBY_HOST}${coderOnePreview}`;
    return (
        <React.Fragment>
            <PayPalScriptProvider
                options={{
                    "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID ?? "",
                    components: "buttons",
                    intent: "subscription",
                    vault: true,
                }}
            >
                <SEO title={title} description={description} socialImage={socialImage} />
                <NavigationHeader />
                <PricingContent />
                <Footer />
            </PayPalScriptProvider>
        </React.Fragment>
    );
};

export default SubscriptionPricing;
