import * as React from "react";
import {
    Root,
    Container,
    Grid,
    Card,
    Subtitle,
    FeatureContainer,
    Headline,
    Description,
    CheckedFeatureLine,
    CheckedIconWrapper,
} from "./PricingContent.styles";
import { H1 } from "../../../H1/H1";
import { LinkButton } from "../../../LinkButton/LinkButton";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import { RoundCheckIcon } from "../../../Icons/RoundCheckIcon";
import { useTranslation } from "react-i18next";
import { PaypalSubscribe } from "./PaypalSubscribe";

export const PricingContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <Container>
                <Headline>Take your skills to the next level</Headline>
                <Subtitle>Free to join. Subscribe for VIP access.</Subtitle>
                <Grid>
                    <Card>
                        <H1>{t("pricingPage.freeTitle")}</H1>
                        <FeatureContainer>
                            <CheckedFeatureLine>
                                <CheckedIconWrapper>
                                    <RoundCheckIcon />
                                </CheckedIconWrapper>
                                <div>{t("pricingPage.freeDescription")}</div>
                            </CheckedFeatureLine>
                        </FeatureContainer>
                        <LinkButton href={CoderOneRoute.Signup}>Get started</LinkButton>
                    </Card>
                    <Card>
                        <H1>{t("vip")}</H1>
                        <FeatureContainer>
                            <Description>Full access to all our best and upcoming features, including:</Description>
                            <CheckedFeatureLine>
                                <CheckedIconWrapper>
                                    <RoundCheckIcon />
                                </CheckedIconWrapper>
                                <div>Train models on our servers. The easiest way to get started with ML.</div>
                            </CheckedFeatureLine>
                            <CheckedFeatureLine>
                                <CheckedIconWrapper>
                                    <RoundCheckIcon />
                                </CheckedIconWrapper>
                                <div>Store 10,000 hours of replays and analytics.</div>
                            </CheckedFeatureLine>
                            <CheckedFeatureLine>
                                <CheckedIconWrapper>
                                    <RoundCheckIcon />
                                </CheckedIconWrapper>
                                <div>Become a VIP member. Get early access to new features.</div>
                            </CheckedFeatureLine>
                            <div>...and more!</div>
                        </FeatureContainer>
                        <PaypalSubscribe type="subscription" />
                    </Card>
                </Grid>
            </Container>
        </Root>
    );
};
