import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { Redirect } from "@reach/router";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import axios from "axios";
import { getAxiosRequestConfig } from "../../../../utilities/getAxiosRequestConfig";
import { Constants } from "../../../../utilities/Constants";
import { useAuthToken } from "../../../../hooks/useAuthToken";
import { useSessionStorage } from "../../../../hooks/useSessionStorage";
import { SessionStorageKey } from "../../../../utilities/SessionStorageKey";
import { getPaypalorderSessionPayload, IOrderDetails, PaypalOrderType } from "@coderone/library";

interface ICreateSubscriptionResponse {
    readonly status: string;
    readonly id: string;
    readonly create_time: string;
    readonly link: Array<{
        readonly href: string;
        readonly rel: string;
        readonly method: string;
    }>;
}

const createSubscriptionRequest = async (token: string | null): Promise<ICreateSubscriptionResponse> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/subscription`, null, config);
    return data;
};

const linkOrderRequest = async (token: string | null, order: IOrderDetails): Promise<ICreateSubscriptionResponse> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/order/link`, { order }, config);
    return data;
};

interface IProps {
    readonly type: string;
}

export const PaypalSubscribe: React.FC<React.PropsWithChildren<IProps>> = ({ type }) => {
    const { authToken, isValid } = useAuthToken();
    const [{ options }, dispatch] = usePayPalScriptReducer();
    const [orderDetails, setOrderDetails] = useSessionStorage(SessionStorageKey.PaypalOrderDetails, null);
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);
    const onApprove = useCallback(
        async (data: any, _actions: any) => {
            const payload = getPaypalorderSessionPayload(data.orderID, PaypalOrderType.CoderOnePro);
            const payloadString = JSON.stringify(payload);
            if (isValid && authToken !== null) {
                await linkOrderRequest(authToken, payload);
                window.location = CoderOneRoute.Dashboard as unknown as Location;
            } else {
                setOrderDetails(payloadString);
            }
        },
        [authToken]
    );

    const onCreateSubscription = useCallback(
        async (_data: any, _actions: any) => {
            const result = await createSubscriptionRequest(authToken);
            return result.id;
        },
        [authToken]
    );

    if (orderDetails !== null) {
        return <Redirect from="*" to={CoderOneRoute.Dashboard} noThrow />;
    }

    return (
        <PayPalButtons
            createSubscription={onCreateSubscription}
            onApprove={onApprove}
            style={{
                label: "subscribe",
            }}
        />
    );
};
