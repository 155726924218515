import styled from "styled-components";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    max-width: 100%;
    background-color: ${Palette.Neutral0};
    display: flex;
    justify-content: center;
    padding: 0px 8px;
`;

export const Container = styled.div`
    width: 1200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 64px 0px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;

    @media screen and (max-width: ${Viewport.Large}px) {
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr;
    }
`;

export const Card = styled.div`
    border-radius: 4px;
    border: 1px solid ${Palette.Neutral20};
    padding: 32px;
    margin: 32px 0px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-row-gap: 16px;
`;

export const Subtitle = styled.p`
    color: ${Palette.Neutral90};
    font-size: 24px;
    margin-top: 8px;
`;

export const FeatureContainer = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 16px;
    text-align: left;
    font-size: 16px;
    color: ${Palette.Neutral90};
`;

export const Headline = styled.h1`
    font-size: 48px;
    font-weight: 700;
`;

export const Description = styled.p`
    color: ${Palette.Neutral90};
    font-size: 16px;
    text-align: left;
`;

export const CheckedFeatureLine = styled.div`
    display: grid;
    grid-template-columns: 16px auto;
    grid-column-gap: 8px;
    justify-items: start;
    text-align: left;
`;

export const CheckedIconWrapper = styled.div`
    color: ${Palette.Primary100};
`;
